/**
 * @fileoverview Main Application driver/entry point
 *
 * Main application driver that maintains and stores
 * all user information during interaction,
 * 
 * @requires React
 * @requires react-router-dom
 * @requires useEffect
 * @requires useState
 * ...
 */

// import dependancies
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';

// Import routes
import Home from './pages/Home';
import Navbar from'./components/Navigation/Navbar';
import Login from './pages/Login';
import Maintance from './pages/Maintance';
import TestWeb3 from './pages/TestWeb3';
import Services from './pages/Services';

function App() {
  return (
    
    <Router>
      <Navbar username=""/>
      <Routes>
      <Route path="/" element={<Maintance/>}/>
        
      </Routes>
    </Router>
  );
}

export default App;
