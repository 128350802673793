import React,{useEffect, useState} from 'react';
import simpleContractABI from '../web3/build/SimpleContract.json';
import tokenArtifact from '../web3/build/SCTKIToken.json';

const Web3 = require('web3');
 

const TestWeb3 = () => {



    const testWalletAddress  ="0x11457e1E0dcBcEC38f272604c94c160629e66780"
    const [simpleContract, setSimpleContract] = useState(null); 
    const [simpleTokenContract, setSimpleTokenContract] = useState(null);
    const [simpleTokenAddress, setTokenAddress] = useState(null);
    


    
    useEffect(() => {
        const web3 = new Web3(window.ethereum);


     
        const contractInstance = new web3.eth.Contract(
          simpleContractABI.abi, // Access the `abi` property
          '0x68eAD652a0BcB4D582D533967842012C35861E34'
        );

        const tokenInstance = new web3.eth.Contract(
            tokenArtifact.abi, 
            "0x33bfC2Ac4A0B283dA3273b29177776cF2b125D92"
            );
 
        setSimpleContract(contractInstance);
        setSimpleTokenContract(tokenInstance);
      }, []);

      const mintFunds = async () => {
        if (simpleTokenContract) {
          try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts[0];
            console.log("Connected account: ", account)
            
            let balance = await simpleTokenContract.methods.checkBalanceOf(account).call();
            console.log(balance);
    
            await simpleTokenContract.methods.transfer("0xd0a9c32a93041d1fd3e2c75bb1006f96f957bd17", 50).send({from:account});

            balance = await simpleTokenContract.methods.checkBalanceOf("0xd0a9c32a93041d1fd3e2c75bb1006f96f957bd17").call();
            console.log(balance);

          } catch (error) {
            console.log('Error', error);
          }
        }
      };
      
      const addSum = async() => {

        const accounts = await window.ethereum.request({method:"eth_requestAccounts"});
        const account = accounts[0];
        

        if(simpleContract){
            try{
                await simpleContract.methods.sum(10).send({from: account })
                console.log(await simpleContract.methods.getData().call());
            }catch(error){
                console.log("Error: ", error);
            }
        }
      }
    
    return(
        <div className='text-center'>
            <h1>Test Page for Web3 Technologies</h1>
            <button onClick={mintFunds}> Get Some Funds!</button>
            <button onClick={addSum}> Add to Sum!</button>
        </div>
    )
    }    

export default TestWeb3;