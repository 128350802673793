const Navbar = (props) => {

    
    const isLoggedIn = (username) => {
       if(username == "" || username == null){
            return false;
       } else{
            return true;
       }
    }

        // renders the login, logout options depending if there is an actively logged user. 
        const userLogOptions =  isLoggedIn(props.username) ? <li id="login" className="nav-item">
                                                                    <a className="nav-link" href="/logout">Logout</a>
                                                                </li>
                                                                :
                                                                <li id="login" className="nav-item">
                                                                    <a className="nav-link" href="/login">Login</a>
                                                                </li>
                                                        
        // renders the Profile and active ticket links if the user is logged in
        const loggedInOptions = isLoggedIn(props.username) ?<div>
                                                                <li id="profile" className="nav-item">
                                                                    <a className="nav-link" href="/profile">Profile</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/faqs">Active Tickets</a>
                                                                </li>
                                                              </div> : null 

    return(
        <nav className="navbar bg-dark" data-bs-theme="dark">
        <div className="container">
            <div>
                {/* Toggler Button & Navbar brand*/}
                
                <button className="navbar-toggler m-2 mx-3"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span>Menu</span>
                </button>
                <a className="navbar-brand" href="/">
                <img src=""/>
                    {props.username ? `Welcome ${props.username}` : `Welcome to TK Industries` }
                    
                </a>
            </div>

            {/* Start of offcanvas menu bar -> handler is navbar-toggler */}
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">TKI - Menu Navigation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                        <li id="register" className="nav-item">
                            <a className="nav-link" href="/register">Register</a>
                        </li>
                        {loggedInOptions}
                        {userLogOptions}
                        <li className="nav-item">
                            <a className="nav-link" href="/about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/services">Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/faqs">FAQs</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/faqs">Contact</a>
                        </li>
                    </ul>
               </div>
             </div>
        </div>
   </nav>
    )
}

export default Navbar;